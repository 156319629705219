<template>
    <div>
        <div v-if="!isLoading">
            <div v-if="typeof users !== 'undefined' && users.length" class="list-group m-b-30">
                <router-link v-for="userItem in users" :key="userItem.id" :to="{name: 'UserEdit', params: {id: userItem.id}}" class="list-group-item">
                    {{userItem.login}} ({{userItem.first_name}} {{userItem.last_name}})
                </router-link>
            </div>
            <b-pagination v-if="totalUsers > payload.limit && !isLoading"
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="payload.limit"
                aria-controls="my-table"
            ></b-pagination>
        </div>
      <div v-else>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                isLoading: false,
                currentPage: 1,
                payload: {
                    filters: {
                        has_source_group: false,
                        parent_or_self: JSON.parse(localStorage.getItem('user')).id
                    },
                    limit: 300,
                    offset: 0
                }
            };
        },
        computed: {
            ...mapGetters('users', ['users']),
        },
        methods: {
            loadUsers() {
                this.isLoading = true;
                this.$store.dispatch('users/getUsersList', this.payload).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        mounted() {
            this.$store.dispatch('users/clearUsersList');
            this.loadUsers();
        },
        watch: {
            currentPage() {
                this.payload.offset = (this.currentPage - 1) * this.payload.limit;
                this.loadUsers();
            }
        }
    }
</script>
<style lang="scss">/*
    .card {
        min-width: 400px;
        min-height: 400px;
    }
    h3 {
        font-weight: 700;
    }
*/</style>
