<template>
  <div class="groups">
    <div class="container" v-if="!loading">
      <div class="card" v-if="!isMobile">
        <div class="card-header">
          <h5 style="font-size: 18px; font-weight: 700">
            {{ $t('settings.Base_groups') }}
          </h5>
          <div class="card-header-right" style="right: 15px"><button class="btn btn-primary" @click="createGroup" style="width: 104px">{{ $t('settings.Create') }}</button></div>
        </div>
        <div class="card-body">
          <group-list v-if="!loading" :groups="groups"/>
          <b-pagination v-if="groupsTotal > payload.limit"
              v-model="currentPage"
              :total-rows="groupsTotal"
              :per-page="payload.limit"
              aria-controls="my-table"
            ></b-pagination>
        </div>
      </div>
      <div class="card" v-if="isMobile">
        <div class="card-header">
          <h5 style="font-size: 18px; font-weight: 700">
            {{ $t('settings.Base_groups') }}
          </h5>
        </div>
        <div style="display:flex; justify-content: center"><button class="btn btn-primary" @click="createGroup">{{ $t('settings.Create') }}</button></div>
        <div class="card-body">
          <group-list v-if="!loading" :groups="groups"/>
          <b-pagination v-if="groupsTotal > payload.limit"
              v-model="currentPage"
              :total-rows="groupsTotal"
              :per-page="payload.limit"
              aria-controls="my-table"
            ></b-pagination>
        </div>
      </div>
      <div>
        <p class="m-b-10">{{ $t("group.without_base_group") }}: <a v-b-modal.without_base class="btn-link">{{ meta.sources.without_groups }} <i class="feather icon-link"></i></a></p>
        <p class="m-b-10">{{ $t("group.total_bases") }}: {{ meta.sources.total }}</p>
        <p class="m-b-10">{{ $t("group.users_not_group") }}: <a v-b-modal.without_groups class="btn-link" v-if="meta.users.without_groups > 0">{{ meta.users.without_groups }} <i class="feather icon-link"></i></a><span v-else>{{ meta.users.without_groups }}</span></p>
        <p class="m-b-10">{{ $t("group.users_total") }}: {{ meta.users.total }}</p>
      </div>
    </div>
    <div v-else-if="loading" class="d-flex justify-content-center">
      <b-spinner/>
    </div>
    <b-modal id="without_base" :title="$t('sources.bases')" size="lg" hide-footer>
      <modal-sources/>
    </b-modal>
    <b-modal id="without_groups" :title="$t('settings.Users')" hide-footer>
      <modal-users/>
    </b-modal>
  </div>
</template>

<script>
import GroupList from "@/components/baseGroups/groupList";
import {mapGetters} from "vuex";
import ModalSources from "@/components/baseGroups/ModalSources"
import ModalUsers from "@/components/baseGroups/ModalUsers"
export default {
  name: "Groups",
  components: {GroupList, ModalSources, ModalUsers},
  data() {
    return {
      loading: false,
      isMobile: false,
      currentPage: 1,
      payload: {
        limit: 300,
        offset: 0
      }
    }
  },
  computed: {
    ...mapGetters('source_groups', ['groups', 'meta', 'groupsTotal']),
  },
  methods: {
    getList() {
      this.loading = true;
      this.$store.dispatch('source_groups/getGroupList', this.payload).then(() => {
        this.loading = false;
      })
    },
    createGroup() {
      this.$router.push({name : "GroupCreate"});
    },
    modalSources() {
      this.$buefy.modal.open({
                    parent: this,
                    component: ModalSources,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
    },
    modalUsers() {
      this.$buefy.modal.open({
                    parent: this,
                    component: ModalUsers,
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true
                })
    },
  },
  watch: {
    currentPage: function (newPage) {
      this.payload.offset = (newPage - 1) * this.payload.limit;
      this.getList();
    }
  },
  mounted() {
    this.getList();
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
  }
}
</script>

<style lang="scss" scoped>


@media (max-width: 992px) {
  .btn{
    padding-left: 8px;
    padding-right: 8px;
    width: calc(100% - 16px);
  }
  .card-header{
    display: flex;
    justify-content: center;
    h5:after{
      display: none;
    }
  }
}

.is-floating {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #4099ff;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  height: 60px;
  width: 60px;
}
@media screen and (max-width: 768px)  {
  .is-floating {
    right: 50px;
  }
}
.user-wrapper {
  position: relative;
}
a {
  color: #1c4ecf;
  text-decoration: underline;
  &.black {
    color: #000;
    text-decoration: none;
  }
}
</style>